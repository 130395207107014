<template>
  <div class='reservation'>
    <d-search :searchData="searchData"
              @searchEvent="searchEvent"
              @resetEvent="resetEvent"/>
    <div class="content">
      <div class="header">
        <div class="operation">
          <el-button size="small"
                     type="primary"
                     @click="addItem">添加
          </el-button>
        </div>
      </div>
      <d-lable :columnData="columnData"
               :tableData="tableData"
               @rowClick="rowClick">
        <template v-slot:handle="{item}">
          <!--  未开启（待开启--status-3--）：可以编辑-删除
                已开启（预约中--status-0--）：不可编辑-删除  可以终止
                点击终止/时间过期了（已结束--status-2--）：不可编辑-删除-终止-->
          <template v-if="item.status==3">
            <el-link type="primary"
                     @click="start(item.id)">开启
            </el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link type="primary"
                     @click="changeItem(item)">修改
            </el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link type="danger"
                     @click="deleteActivity(item)" v-if="item.status==3">删除
            </el-link>
          </template>
          <template>
            <el-link type="primary"
                     @click="stopActivity(item)" v-if="item.status==0">终止
            </el-link>
          </template>
        </template>
      </d-lable>
    </div>
    <d-paging :pager="pager"
              @change="getItemList">
    </d-paging>

    <el-dialog :title="dialog.title"
               :visible.sync="dialog.show"
               width="40%"
               @close="dialogClose">
      <el-form ref="form"
               :model="dialog.form"
               :rules="formRules"
               label-width="100px">
        <el-form-item label="活动名称：" prop="name">
          <el-input v-model="dialog.form.name"
                    :disabled="dialog.disabled"></el-input>
        </el-form-item>
        <el-form-item label="活动类型：" prop="contentType">
          <el-input v-model="dialog.form.contentType"
                    :disabled="dialog.disabled"></el-input>
        </el-form-item>
        <el-form-item label="活动内容：" prop="desc">
          <el-input type="textarea"
                    v-model="dialog.form.desc"
                    :disabled="dialog.disabled"></el-input>
        </el-form-item>
        <el-form-item label="负责人：" prop="charge">
          <el-input v-model="dialog.form.charge"
                    :disabled="dialog.disabled"></el-input>
        </el-form-item>
        <el-form-item label="部门：" prop="deptId">
          <department v-model="dialog.form.deptId" :disabled="dialog.disabled" :checkStrictly="true" :filterShop="true"></department>
        </el-form-item>
        <el-form-item label="预约时间：" prop="time">
          <el-date-picker v-model="dialog.form.time" class="mgb10"
                          :disabled="dialog.disabled"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd">
          </el-date-picker>
          <div class="hour"
               v-for="(el,index) in dialog.form.hourTime"
               :key="el+index">
            <el-form-item :prop="'hourTime.'+index+'.startTime'" :rules="{
              required:true,message:'不能为空',trigger:'change'
            }">
              <el-time-picker placeholder="起始"
                              :disabled="dialog.disabled"
                              v-model="el.startTime"
                              value-format="HH:mm"
                              format="HH:mm">
              </el-time-picker>
            </el-form-item>

            <span class="vertical">~</span>
            <el-form-item :prop="'hourTime.'+index+'.endTime'" :rules="{
              required:true,message:'不能为空',trigger:'change'
            }">
              <el-time-picker placeholder="结束"
                              :disabled="dialog.disabled"
                              v-model="el.endTime"
                              value-format="HH:mm"
                              format="HH:mm">
              </el-time-picker>
            </el-form-item>
            <el-form-item :prop="'hourTime.'+index+'.count'" :rules="[
                {required:true,message:'不能为空',trigger:'blur'},
                countRule
                ]">
              <el-input v-model="el.count"
                        :disabled="dialog.disabled"
                        placeholder="人数"></el-input>
            </el-form-item>
            <div class="addBtn"
                 v-if="index === 0"
                 :disabled="dialog.disabled"
                 @click="addHourTime">
              +
            </div>
            <div class="addBtn"
                 v-if="index !== 0"
                 :disabled="dialog.disabled"
                 @click="delHourTime(index)">
              -
            </div>
          </div>


        </el-form-item>
        <el-form-item label="活动地点：" prop="addr">
          <el-input v-model="dialog.form.addr"
                    :disabled="dialog.disabled"></el-input>
        </el-form-item>
        <el-form-item label="预约说明：" prop="remark">
          <el-input type="textarea"
                    v-model="dialog.form.remark"
                    :disabled="dialog.disabled"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialog.show = false">取 消</el-button>
        <el-button type="primary" v-throttle
                   @click="confirmDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import DSearch from '@/components/d-search'
import DLable from '@/components/d-table'
import DPaging from '@/components/d-paging'
import Department from "@/components/department.vue";
import {validInt} from "@/utils/validator";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    DSearch,
    DLable,
    DPaging,
    Department
  },
  data() {
    //这里存放数据
    return {
      dialog: {
        show: false,
        title: null,
        disabled: false,
        form: {
          hourTime: [
            {
              endTime: null,
              startTime: null,
              count: null
            }
          ],
          deptId: null,
        },
      },
      formRules: {
        name: this.$formValidator.empty('活动名称'),
        contentType: this.$formValidator.empty('活动类型'),
        desc: this.$formValidator.empty('活动内容'),
        charge: this.$formValidator.empty('负责人'),
        deptId: this.$formValidator.empty('部门', 'change', 'number'),
        time: this.$formValidator.empty('预约时间', 'change', 'array'),
        addr: this.$formValidator.empty('活动地点'),
        remark: this.$formValidator.empty('预约说明')
      },
      countRule: {
        validator: validInt
      },
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      searchData: {
        searchItem: [
          {
            label: '活动名称',
            value: 'name',
            type: 'commonInput',
            dateType: 'month',
            placeholder: '请输入活动名称'
          },
          {
            label: '预约日期',
            value: 'commitTime',
            type: 'scopeDate',
            startPlaceholder: "开始日期",
            endPlaceholder: "结束日期"
          }
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {}
      },
      columnData: [
        {prop: 'name', label: '活动名称', columnType: 'click'},
        {prop: 'contentType', label: '活动类型'},
        {prop: 'content', label: '活动内容', width: 120},
        {prop: 'addr', label: '活动地点', width: '140'},
        {prop: 'date', label: '预约时间'},
        {prop: 'charge', label: '负责人'},
        {prop: 'statusText', label: '状态'},
        {
          prop: 'operate',
          label: '操作',
          width: 220,
          type: {}
        }
      ],
      tableData: [
        {
          name: '1'
        }
      ]
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    /**
     * 搜索按钮点击
     **/
    searchEvent() {
      this.getItemList()
    },
    /**
     * 重置按钮点击
     **/
    resetEvent() {
      this.getItemList()
    },

    stopActivity(item) {
      this.$api.busapi.stopActivity(item.id)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.getItemList()
            } else {
              this.$message.error(res.msg);
            }
          })
    },

    deleteActivity(item) {
      this.$api.busapi.deleteActivity(item.id)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.getItemList()
            } else {
              this.$message.error(res.msg);
            }
          })
    },

    // 获取列表
    async getItemList() {
      const params = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        name: this.searchData.params.name,
        startDate: this.searchData.params.commitTime && this.searchData.params.commitTime[0],
        endDate: this.searchData.params.commitTime && this.searchData.params.commitTime[1]
      }
      const listData = await this.$api.busapi.getActivityManagerList(params)
      if (listData.code === 200) {
        this.pager.count = listData.data.total
        this.tableData = listData.data.list.map(item => ({
          ...item,
          statusText: ['预约中', '已结束', '已结束', '未开启'][item.status],
          date: `${item.startDate}——${item.endDate}`
        }))
      }
    },

    // 点击添加按钮
    addItem() {
      const {dialog} = this;
      dialog.title = '添加';
      dialog.show = true;
      this.$refs.form && this.$refs.form.clearValidate()
    },

    start(id) {
      this.$api.busapi.startActivity(id).then(({code}) => {
        code == 200 && this.getItemList()
      })
    },

    // 修改列表item
    async changeItem(item) {
      await this.getActivityInfo(item.id);
      this.dialog.title = '编辑';
      this.dialog.show = true;
      this.$refs.form && this.$refs.form.clearValidate();
    },

    // dialog的时间段添加
    addHourTime() {
      const {form, disabled} = this.dialog
      const length = form.hourTime.length
      if (disabled) return
      form.hourTime.push({
        endTime: null,
        startTime: null,
        count: null
      })
    },

    delHourTime(index) {
      this.dialog.form.hourTime.splice(index, 1)
    },
    // dialog确定
    confirmDialog() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const {name, contentType, desc, charge, deptId, time, hourTime, addr, remark, id} = this.dialog.form
          let list;
          if(hourTime.some(e=>e.startTime>e.endTime)){
            return this.$message.warning('起始时间要小于结束时间');
          }
          list = hourTime.reduce((ac, item) => {
            if ((item.count && item.endTime && item.startTime)) {
              ac.push({
                applicantsNumber: item.count,
                endPeriod: item.endTime,
                startPeriod: item.startTime,
                id: item.id
              })
            }
            return ac;
          }, [])
          const params = {
            name,
            content: desc,
            charge,
            startDate: time && time[0],
            endDate: time && time[1],
            list,
            deptId,
            addr,
            remark,
            contentType
          }
          if (this.dialog.title === '添加') {
            this.addItemEvent(params)
          } else if (this.dialog.title === '编辑') {
            params.id = id
            this.putActivityManager(params)
          }

        }
      })

    },

    async addItemEvent(params) {
      const data = await this.$api.busapi.addActivityManager(params)
      if (data.code === 200) {
        this.$message.success(data.msg);
        this.getItemList()
      } else {
        this.$message.error(data.msg);
      }
      this.dialog.show = false
    },

    async putActivityManager(params) {
      const data = await this.$api.busapi.putActivityManager(params)
      if (data.code === 200) {
        this.$message.success(data.msg);
        this.getItemList()
      } else {
        this.$message.error(data.msg);
      }
      this.dialog.show = false
    },

    async getActivityInfo(id) {
      const info = await this.$api.busapi.getActivityInfo(id)
      if (info.code === 200) {
        this.dialog.form = {
          name: info.data.name,
          desc: info.data.content,
          deptId: info.data.deptId,
          charge: info.data.charge,
          time: [info.data.startDate, info.data.endDate],

          hourTime: (info.data.list || []).map(item => ({
            endTime: item.endPeriod,
            startTime: item.startPeriod,
            count: item.applicantsNumber,
            id: item.id
          })),
          id: info.data.id,
          addr: info.data.addr,
          remark: info.data.remark,
          contentType:info.data.contentType
        }
      }
    },

    // dialog关闭
    dialogClose() {
      this.dialog.disabled = false
      this.dialog.form = {
        name: null,
        desc: null,
        deptId: null,
        dept: null,
        time: null,
        id: null,
        hourTime: [
          {
            endTime: null,
            startTime: null,
            count: null
          }
        ],
        site: null,
        instr: null
      }
    },

    // 点击活动名称
    async rowClick(item) {
      await this.getActivityInfo(item.id)
      this.dialog.title = '查看'
      this.dialog.disabled = true
      this.dialog.show = true
    },

    // 查询职位列表
    getSysPositionList() {
      const obj = {
        pageNum: -1,
        status: 1
      }
      this.$api.sysapi.getSysPositionList(obj)
          .then(({code, data, msg}) => {
            if (code === 200) {
              this.dialog.dept = data.list
            }
          })
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.getSysPositionList()
    this.getItemList()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {
  }, //生命周期 - 创建之前
  beforeMount() {
  }, //生命周期 - 挂载之前
  beforeUpdate() {
  }, //生命周期 - 更新之前
  updated() {
  }, //生命周期 - 更新之后
  beforeDestroy() {
  }, //生命周期 - 销毁之前
  destroyed() {
  }, //生命周期 - 销毁完成
  activated() {
  }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.reservation {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
  margin-top: -20px;
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;

  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-end;
    // .operation {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    //   display: flex;
    //   div {
    //     line-height: 28px;
    //   }
    //   .el-button {
    //     margin-left: 20px;
    //   }
    //   .el-checkbox {
    //     height: 32px;
    //   }
    // }
  }

  .el-table {
    flex: 1;
    margin-top: 20px;
    overflow-y: auto;
  }
}

.d-paging {
  text-align: center;
}

::v-deep .el-form {
  width: 80%;
  margin: 0 auto;

  .el-select {
    width: 100%;
  }

  .el-range-editor.el-input__inner {
    width: 100%;
  }

  .hour {
    position: relative;
    display: flex;

    .vertical {
      display: inline-block;
      margin: 0 2%;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }

    .el-input {
      width: 90%;

      & > :last-child {
        margin-left: 10%;
      }
    }

    .addBtn {
      position: absolute;
      right: -40px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px dashed #ccc;
      font-size: 28px;
      color: #ccc;
      font-weight: 200;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding-bottom: 4px;
      padding-left: 0.5px;
      cursor: pointer;
    }
  }
}

::v-deep .el-dialog__header {
  box-shadow: 0px 5px 6px -8px #555;
}

</style>