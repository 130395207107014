<template>
  <div class="reportMarket"
       v-loading="loading">
    <d-search :searchData="searchData"
              @searchEvent="searchEvent"
              @resetEvent="resetEvent" />
    <div class="action_btn">
      <el-button type="primary" @click="batchFun(1)">批量开启</el-button>
      <el-button type="info" @click="batchFun(2)">批量暂停</el-button>
      <el-button type="danger" @click="batchFun(3)">批量删除</el-button>
    </div>
    <d-lable :tableData='tableData'
             :columnData='columnData'
             @selection-change="handleSelectionChange">

      <template v-slot:handle="row">
        <el-link type="primary"
                 @click="startPosUserCashier(row.item)"
                 v-if="row.item.disabled">启用</el-link>
        <el-link type="primary"
                 @click="stopPosUserCashier(row.item)"
                 v-else>暂停</el-link>
        <el-divider direction="vertical"></el-divider>
        <el-link type="danger"
                 @click="deleteFun(row.item)">删除</el-link>
      </template>
    </d-lable>
    <d-paging :pager="pager"
              @change="getItemList" />
  </div>
</template>

<script>

import DSearch from '@/components/d-search'
import DLable from '@/components/d-table'
import DPaging from '@/components/d-paging'
import {getShopCode} from "@/utils/utils";
export default {
  data () {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      loading: false,
      searchData: {
        searchItem: [{
          label: '姓名',
          value: 'name',
          type: 'commonInput',
          dateType: 'month',
          placeholder: '请输入姓名'
        }, {
          label: '选择店铺',
          value: 'shopIdList',
          type: 'linkageSelect',
          dateType: 'month',
          placeholder: '',
          options: [],
          optionProps: {
            value: 'name',
            label: 'name',
            children: 'childList'
          }
        }, {
          label: '状态',
          value: 'disabled',
          type: 'commonSelect',
          placeholder: '',
          options: [
            {
              label: '启用',
              value: 0,

            },
            {
              label: '停用',
              value: 1
            }
          ],
        }],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {}
      },
      columnData: [
        { prop: 'selection', type: 'selection', label: ''},
        { prop: 'username', label: '姓名' },
        { prop: 'floorName', label: '楼层' },
        { prop: 'shopCode', label: '店铺号', width: '120' },
        { prop: 'shopName', label: '店铺名称', width: '120' },
        { prop: 'updateTime', label: '更新时间' },
        { prop: 'statusText', label: '状态' },
        {
          prop: 'operate', label: '操作', type: {}
        }
      ],
      tableData: [],
      ids: []
    }
  },
  computed: {
  },
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.getShopList()
      this.getItemList()
    },

    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.shopapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'linkageSelect') {
                ele.options = child
              }
            })
          }
        })
    },
    startPosUserCashier (item) {
      this.$api.busapi.startPosUserCashier(item.id)
        .then((res) => {
          this.responseHandle(res)
        })
    },

    stopPosUserCashier (item) {
      this.$api.busapi.stopPosUserCashier(item.id)
        .then((res) => {
            this.responseHandle(res)
        })
    },
    deleteFun (item) {
      this.$api.busapi.deletePostUserCashier(item.id)
        .then((res) => {
            this.responseHandle(res)
        })
    },
    handleSelectionChange (selects) {
      this.ids = selects.map(e => e.id)
    },
    responseHandle (res) {
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.getItemList()
      } else {
        this.$message.error(res.msg);
      }
    },
    batchFun (n) {
      const ids = this.ids
      if(!ids || !ids.length) {
        return this.$message.warning('请先选择需要批量操作的数据')
      }
      const Api = n === 1 ? 'batchStartPosUserCashier' : n === 2 ? 'batchStopPosUserCashier' : 'batchDelPostUserCashier'
      this.$api.busapi[Api]({ ids: this.ids }).then((res) => {
        this.responseHandle(res)
      })
    },
    /*,*
     * 搜索按钮点击
     **/
    searchEvent () {
      this.getItemList()
    },
    /**
     * 重置按钮点击
     **/
    resetEvent () {
      this.pager.pageNum = 1;
      this.pager.pageSize = 10;
      this.getItemList()
    },

    // 分页列表
    getItemList () {
      this.ids = [] // 每次查询先清空上次的选择数据
      const params = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        disabled: this.searchData.params.disabled,
        username: this.searchData.params.name,
        shopCode: getShopCode(this.searchData.params.shopIdList)
      }
      this.$api.busapi.getPosUserCashierList(params)
        .then((res) => {
          if (res.code === 200) {
            this.pager.count = res.data.total
            this.tableData = res.data.list.map(item => ({
              ...item,
              statusText: !item.disabled ? '启用' : '停用'
            }))
          }
        })
    }

  },

}
</script>
<style lang="scss" scoped>
.reportMarket {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  .el-table {
    flex: 1;
    margin-top: 30px;
    padding: 0 20px;
    overflow-y: auto;
  }
  .d-paging {
    margin-top: 50px;
    text-align: center;
  }
  .action_btn {
    margin-right: 20px;
    display: flex;
    justify-content: right;
  }
}
</style>  
