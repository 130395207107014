<template>
  <component :is="activeComponent"></component>
</template>

<script>
import OpenDeposit from './cashier-manage-component/opening-deposit.vue'
import NoOpenDeposit from './cashier-manage-component/noOpening-deposit.vue'
export default {
  components: {
    OpenDeposit,
    NoOpenDeposit
  },
  data () {
    return {
      activeComponent: ''
    }
  },
  created () {
    this.getConfig()
  },
  methods: {
    getConfig () {
      this.$api.sysapi.getOpenDeposit().then(({data: {openDeposit}}) => {
        this.activeComponent = openDeposit ? 'OpenDeposit' : 'NoOpenDeposit'
      })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>  
